<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let title: string;
	export let image: string;
	export let imageMobile: string;
	export let index: number;
	export let loading: "eager" | "lazy";

	export let urlSlug: string | undefined = undefined;
</script>

<a
	href="/{urlSlug}"
	class="group relative flex h-full cursor-pointer flex-col sm:h-[26rem] sm:w-[16.938rem] md:h-[23.375rem] md:w-[19rem] lg:w-[26.25rem]"
>
	<span
		class="font-quicksand group-hover:text-tertiary lg::text-end text-gray left-0 top-0 mb-3 ml-auto text-[4.375rem] leading-[3.3rem] lg:absolute lg:mb-0"
		>{index}</span
	>
	<div class="relative sm:h-[16.938rem] sm:w-[16.938rem] md:ml-auto md:h-64 md:w-64 lg:h-[20.313rem] lg:w-[20.313rem]">
		<StaticPicture
			{image}
			alt={title}
			width={325}
			height={325}
			class="aspect-square md:ml-auto"
			{loading}
			sourcesets={{
				"<lg": imageMobile,
				">lg": image,
			}}
		/>
		<div
			class="bg-secondary absolute inset-0 opacity-0 mix-blend-color transition-opacity duration-200 group-hover:opacity-100"
		></div>
		<div class="bg-secondary absolute inset-0 opacity-0 transition-opacity duration-200 group-hover:opacity-40"></div>
		<span
			class="font-quicksand absolute left-1/2 top-1/2 z-20 hidden -translate-x-1/2 -translate-y-1/2 text-[14rem] text-white group-hover:inline"
			>+</span
		>
	</div>

	<div
		class="border-gray text-bito-black group-hover:text-tertiary md:text-md bottom-0 left-0 flex h-full w-full items-center border bg-white p-4 text-xs font-extrabold md:absolute md:max-h-24 md:w-[16rem] md:p-6 lg:h-auto lg:w-[24.5rem]"
	>
		{nbspify(title)}
	</div>
</a>
